import React from 'react';
import './HeroSection.css'
import HeroSection from './HeroSection';
import Cards from './Card';
const OverView = () => {
  return (
    <div className='homqe'>
      <HeroSection/>
      <Cards/>
    </div>
  );
};

export default OverView;